<template>
  <ZNavbarNav class="dashboard-navbar">
    <ZNavItem
      :to="localePath('/dashboard')"
      @click="emit('navCollapse')"
    >
      {{ t('pageLink.dashboard') }}
      <span
        v-if="showDashboardBadge"
        class="push-note"
      />
    </ZNavItem>

    <ZNavItem
      :to="localePath('/dashboard/trips')"
      @click="emit('navCollapse')"
    >
      {{ t('renterBookings') }}
    </ZNavItem>
    <template v-if="user && user.HasRv">
      <ZNavItem
        data-testid="dashboard-bookings"
        :to="localePath('/dashboard/bookings')"
        @click="emit('navCollapse')"
      >
        {{ t('ownerBookings') }}
      </ZNavItem>
      <ZNavItem
        :to="localePath('/dashboard/calendar')"
        no-prefetch
        @click="emit('navCollapse')"
      >
        {{ t('pageLink.calendar') }}
      </ZNavItem>
      <ZNavItem
        :to="localePath('/dashboard/insights')"
        no-prefetch
        @click="emit('navCollapse')"
      >
        {{ t('pageLink.insights') }}
      </ZNavItem>
    </template>
    <ZNavItem
      :to="localePath('/dashboard/profile')"
      no-prefetch
      @click="emit('navCollapse')"
    >
      {{ t('pageLink.profile') }}
    </ZNavItem>

    <!-- Permissions are currently not available server-side -->
    <ClientOnly>
      <ZNavItem
        v-if="isAdmin"
        :href="runtimeConfig.public.apps.operationsDashboard.baseURL"
        @click="emit('navCollapse')"
      >
        Operations
      </ZNavItem>
    </ClientOnly>
  </ZNavbarNav>
</template>

<script setup lang="ts">
defineProps<{
  showDashboardBadge?: boolean
}>()

const emit = defineEmits<{
  navCollapse: []
}>()

const { t } = useI18n()
const { user } = useAuthentication()
const { isAdmin } = useUser()
const localePath = useLocalePath()
const runtimeConfig = useRuntimeConfig()
</script>

<i18n lang="json">
{
  "en": {
    "ownerBookings": "Bookings",
    "renterBookings": "Trips"
  },
  "fr": {
    "ownerBookings": "Réservations",
    "renterBookings": "Voyages"
  }
}
</i18n>
