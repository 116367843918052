<template>
  <ZButton
    :link="isLink"
    :class="['buy-rv-btn', { 'darker-text': usePrimary200 }]"
    :variant="btnVariant"
    :outline="useWhite || useOutline"
    @click.prevent="rentAnRvLinkClicked"
  >
    {{ textBtn }}
  </ZButton>
</template>

<script setup lang="ts">
import { ROUTE_BASE_NAME } from '~/constants'
import { QueryStrings } from '~/lib/enums'

const props = defineProps<{
  textBtn: string
  useHighlight?: boolean
  useWhite?: boolean
  isLink?: boolean
  usePrimary200?: boolean
  useOutline?: boolean
}>()

const { $search } = useNuxtApp()
const localePath = useLocalePath()
const locationModel = ref($search.parameters?.location)

async function rentAnRvLinkClicked() {
  const newQuery = {
    [QueryStrings.searchAddress]: locationModel.value?.fullName || undefined,
  }

  await navigateTo(
    {
      path: localePath(ROUTE_BASE_NAME.rv_search),
      query: newQuery,
    },
  )
}

const btnVariant = computed(() => {
  return props.useHighlight ? 'highlight' : props.useWhite ? 'white' : 'primary'
})
</script>

<style scoped lang="scss">
.zlink {
  text-decoration: none;
  font-size: 0.875rem;
  padding-left: 0.875rem;
  padding-right: 0.875rem;

  @include media-max-size(xLarge) {
    font-size: 1rem;
    padding: 0;
  }
}
.darker-text {
  color: getColor('primary-200');
  font-size: 1rem;
  padding: 0;
  font-weight: 400;
}
</style>
